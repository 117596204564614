import { CircleHelp } from "lucide-react";
import GoogleIcon from "./svg/GoogleIcon";
import FacebookIcon from "./svg/Facebook";
import Tiktok from "./svg/Tiktok";
import Apple from "./svg/Apple";
import { Link, useNavigate, useSearchParams } from "react-router-dom";
import { Fragment, useEffect, useState } from "react";
import { useGoogleLogin } from "@react-oauth/google";
import Swal from "sweetalert2";
import ButtonLogin from "./components/ButtonLogin";
import { cn } from "./lib/utils";
import axios from "axios";
import FacebookLogin from "react-facebook-login";
import qs from "qs";
import AccountNotFound from "./components/AccountNotFound";
import { createQuery } from "./lib/utils";
import LoadingScreen from "./components/Loader";

function App() {
  const [params] = useSearchParams();
  const [error, setError] = useState(false);
  const [isRead, setIsRead] = useState(false);
  const [acceptedTermsDel, setAcceptedTermsDel] = useState(false);
  const [user, setUser] = useState({
    token: "",
    info: {},
  });
  const tiktokCode = params.get("tiktok_code");
  const [message, setMessage] = useState("");
  const navigate = useNavigate();
  const [loading, setLoading] = useState(false);

  const loginGG = useGoogleLogin({
    onSuccess: (tokenResponse) => {
console.log(tokenResponse)      
setLoading(true);
      axios
        .post(
          `${process.env.REACT_APP_API_URL}/v2/access/provider-connect/google`,
          {
            data: qs.stringify({
              access_token: tokenResponse.access_token,
            }),
          }
        )
        .then((res) => {
          const { error } = res.data;
          
          if (error) {
            setMessage(error);
            Swal.fire({
              icon: "warning",
              title: "Login With Google",
              text: "Account Not Found!",
              showConfirmButton: false,
              timer: 1500,
            });
            return;
          }

          Swal.fire({
            icon: "success",
            title: "Verified Success",
            text: "Successfully authenticated customer information.",
            showConfirmButton: false,
            timer: 1500,
          });
          setUser((prev) => ({
            ...prev,
            token: res.data.jwt,
            info: res.data.user.attributes,
          }));
        })
        .catch(() => {
          Swal.fire({
            icon: "error",
            title: "Oops...",
            text: "Something went wrong!",
            showConfirmButton: false,
            timer: 1500,
          });
        })
        .finally(() => setLoading(false));
    },
    onError: () => {
      Swal.fire({
        icon: "error",
        title: "Oops...",
        text: "Something went wrong!",
        showConfirmButton: false,
        timer: 1500,
      });
      setLoading(false);
    },
  });

  const loginTT = () => {
    window.open(
      "https://www.tiktok.com/v2/auth/authorize" +
        `?${createQuery({
          client_key: process.env.REACT_APP_TIKTOK_CLIENT_ID,
          response_type: "code",
          scope: "user.info.basic,user.info.profile",
          redirect_uri: process.env.REACT_APP_TIKTOK_REDIRECT_URI,
        })}`,
      "_self"
    );
  };

  const loginFB = (res) => {
    axios
      .get(
        `${process.env.REACT_APP_API_URL}/auth/facebook/callback?access_token=${res.access_token}`
      )
      .then((res) => {
        Swal.fire({
          icon: "success",
          title: "Verified Success",
          text: "Successfully authenticated customer information.",
          showConfirmButton: false,
          timer: 1500,
        });
        setUser((prev) => ({
          ...prev,
          token: res.data.jwt,
          info: res.data.user.attributes,
        }));
      })
      .catch(() => {
        Swal.fire({
          icon: "error",
          title: "Oops...",
          text: "Something went wrong!",
          showConfirmButton: false,
          timer: 1500,
        });
      });
  };

  const onLogin = ({ by }) => {
    if (!isRead) {
      setError(true);
      Swal.fire({
        icon: "warning",
        title: "Oops...",
        text: "Please make sure you have read and accepted the terms before continuing!",
      });
      return;
    } else {
      setError(false);
      switch (by) {
        case "GG":
          loginGG();
          break;
        case "TT":
          loginTT();
          break;
        case "AP":
          break;
        default:
          break;
      }
    }
  };

  const onClearState = () => {
    setUser({ token: "", info: {} });
    setIsRead(false);
    setAcceptedTermsDel(false);
  };

  const onDeleteAccount = () => {
    if (!acceptedTermsDel) {
      Swal.fire({
        icon: "warning",
        title: "Oops...",
        text: "Please make sure you have read and accepted the terms before continuing!",
      });
      setError(true);
      return;
    } else {
      setError(false);
      Swal.fire({
        title: "Are you sure??",
        text: `You are deleting account ${
          user.info.email ?? user.info.username
        } on ShortDrama`,
        showCancelButton: true,
        confirmButtonColor: "#20d735",
        cancelButtonColor: "#EF4444",
        confirmButtonText: "I am sure",
        cancelButtonText: `I'm thinking again.`,
      }).then((result) => {
        if (result.isConfirmed) {
          axios
            .request({
              url: `${process.env.REACT_APP_API_URL}/v2/del-acc/provider-connect`,
              method: "POST",
              headers: {
                Authorization: `Bearer ${user.token}`,
              },
            })
            .then(() => {
              Swal.fire({
                icon: "success",
                title: "Delete Account",
                text: "Successfully!",
                showConfirmButton: false,
                timer: 1500,
              }).then(() => {
                onClearState();
              });
            })
            .catch((err) => {
              Swal.fire({
                icon: "error",
                title: "Oops...",
                text: "Something went wrong! " + err.message,
                showConfirmButton: false,
                timer: 1500,
              });
            });
        }
      });
    }
  };

  const onCancelDelAccount = () => {
    setUser({
      token: "",
      info: {},
    });
    setIsRead(false);
    setAcceptedTermsDel(false);
  };

  const onRetrieveLoginByTiktok = () => {
    setLoading(true);
    axios
      .post(
        `${process.env.REACT_APP_API_URL}/v2/access/provider-connect/tiktok`,
        {
          data: qs.stringify({
            access_token: tiktokCode,
          }),
        }
      )
      .then((res) => {
        const { error } = res.data;

        if (error) {
          if (error.includes("invalid")) {
            Swal.fire({
              icon: "warning",
              title: "Login With Tiktok",
              text: "Something went wrong! Please login again!",
              showConfirmButton: false,
              timer: 1500,
            });
            navigate("/shortdrama/deleteaccount");
            window.location.reload();
            return;
          }

          setMessage(error);
          Swal.fire({
            icon: "warning",
            title: "Login With Tiktok",
            text: "Account Not Found!",
            showConfirmButton: false,
            timer: 1500,
          });
          return;
        }

        Swal.fire({
          icon: "success",
          title: "Verified Success",
          text: "Successfully authenticated customer information.",
          showConfirmButton: false,
          timer: 1500,
        });
        setUser((prev) => ({
          ...prev,
          token: res.data.jwt,
          info: res.data.user.attributes,
        }));
      })
      .catch((err) => {
        Swal.fire({
          icon: "error",
          title: "Verify User Tiktok",
          text: "Something went wrong! " + err.message,
          showConfirmButton: false,
          timer: 1500,
        });
      })
      .finally(() => setLoading(false));
  };

  useEffect(() => {
    if (tiktokCode) {
      onRetrieveLoginByTiktok();
    }
  }, [tiktokCode]);

  return (
    <Fragment>
      <div className="flex justify-center items-center overflow-y-hidden fixed top-0 left-0 right-0 bottom-0 bg-[url('../public/bg.jpg')]">
        <div className="relative rounded-lg sm:mx-0 mx-2 border-2 sm:pb-8 pb-4 border-[#FFF6E4] bg-[#FFFBF4] shadow-xl max-w-[480px] w-full h-fit flex flex-col space-y-1 sm:px-[56px] px-10">
          <div className="group/buttonHelp absolute top-4 right-4">
            <div className="relative">
              {user.token === "" && (
                <button className="rounded-full overflow-hidden" type="button">
                  <CircleHelp className="size-4 text-red-500 bg-yellow-200" />
                </button>
              )}
              <div className="absolute group-hover/buttonHelp:flex hidden border-red-400 p-4 right-0 top-[105%] bg-white w-[288px] rounded-md shadow-lg">
                Guest account doesn't bound to User Data, you can delete your
                cache and uninstall the app to delete your account.
              </div>
            </div>
          </div>
          <div className="sm:h-9 h-3"></div>
          <div className="flex flex-row items-start space-x-4">
            <div>
              <img
                alt="logo"
                src={""}
                className="size-[70px] rounded-md border shadow-lg"
                loading="lazy"
              />
            </div>
            <div className="text-xl">
              <div className="font-bold">ShortDrama</div>
              <div className="font-medium text-lg">Stream Drama & TV</div>
            </div>
          </div>
          {message === "" ? (
            <div className="flex flex-col space-y-2 items-center sm:pt-11 pt-6">
              <div className={cn(["sm:text-2xl text-lg font-bold italic"])}>
                {user.token === ""
                  ? "Delete My Account"
                  : `Hello ${user.info.username}`}
              </div>
              <div
                className={cn([
                  "sm:text-sm text-xs",
                  user.token === "" && "pb-6",
                ])}
              >
                {user.token === ""
                  ? "Please Login to verify your account before deleting it."
                  : "Your account will be permanently deleted and:"}
              </div>
              <div
                className={cn([
                  "flex flex-col space-y-6 w-full items-stretch pb-6",
                ])}
              >
                {user.token === "" && (
                  <FacebookLogin
                    appId={process.env.REACT_APP_FACEBOOK_LOGIN_APP_ID}
                    fields="name,email,picture"
                    cssClass="h-14 w-full font-bold select-none flex flex-row justify-center items-center border-[#3E67B5] border rounded-full bg-[#3E67B5] text-white shadow-lg shadow-blue-500 transition-all duration-500  hover:translate-x-4 hover:shadow-md"
                    callback={(res) => loginFB(res)}
                    textButton="Sign In With Facebook"
                    icon={
                      <div className="sm:mr-5 mr-2">
                        <FacebookIcon />
                      </div>
                    }
                    onFailure={(err) => {
                      Swal.fire({
                        icon: "error",
                        title: "Login With Facebook",
                        text: "Something went wrong: " + err?.message,
                        showConfirmButton: false,
                        timer: 1500,
                      });
                    }}
                  />
                )}
                {user.token === "" ? (
                  [
                    {
                      label: "Sign In With Google",
                      icon: <GoogleIcon />,
                      className: cn([
                        "h-14 border border-gray-500 rounded-full text-white bg-black shadow-lg shadow-black transition-all duration-500 hover:translate-x-4 hover:shadow-md",
                      ]),
                      action: () => onLogin({ by: "GG" }),
                    },
                    {
                      label: "Sign In With Tiktok",
                      icon: <Tiktok />,
                      className: cn([
                        "h-14 border-black border rounded-full bg-white shadow-lg shadow-gray-500 transition-all duration-500 hover:translate-x-4 hover:shadow-md",
                      ]),
                      action: () => onLogin({ by: "TT" }),
                    },
                    {
                      label: "Sign In With Apple",
                      icon: <Apple />,
                      className: cn([
                        "h-14 border-black border rounded-full bg-white shadow-lg shadow-slate-500 transition-all duration-500 hover:translate-x-4 hover:shadow-md",
                      ]),
                      action: () => onLogin({ by: "AP" }),
                    },
                  ].map((button, idx) => (
                    <ButtonLogin
                      label={button.label}
                      action={button.action}
                      className={button.className}
                      icon={button.icon}
                      key={idx}
                    />
                  ))
                ) : (
                  <div className=" bg-white max-h-[250px] sm:text-sm text-xs text-gray-500 min-h-[250px] h-full overflow-auto py-2 pl-5 w-full shadow-md">
                    <ul className="list-disc leading-relaxed">
                      <li>
                        You will not be able to log in to the app with the
                        account.
                      </li>
                      <li>
                        Your personal and historical information (including ID,
                        username, browsing records and third-party account
                        binding) will all be cleared.
                      </li>
                      <li>
                        Your account assets like Coins/Diamonds will be cleared.
                        Your VIP privileges will be cancelled and cannot be
                        renewed.
                      </li>
                      <li>
                        After deletion, as automatic subscriptions will continue
                        to be valid, you can cancel them or apply for a refund
                        in Google Play Store (Profile/Payment&Subscription)
                        /Apple Store.(
                        <Link
                          className="hover:underline"
                          to={"https://apps.apple.com/account/subscriptions"}
                          target="_blank"
                        >
                          https://apps.apple.com/account/subscriptions
                        </Link>
                        ).
                      </li>
                      <li>The deleted account cannot be restored.</li>
                      <li>
                        You can also delete your account in the
                        APP/Profile/Settings.
                      </li>
                    </ul>
                  </div>
                )}
              </div>
            </div>
          ) : (
            <AccountNotFound />
          )}

          <label
            className={cn([
              "flex flex-row space-x-1 items-start select-none",
              message !== "" && "!hidden",
            ])}
          >
            {user.token === "" ? (
              <Fragment>
                <input
                  type="checkbox"
                  className="rounded-full"
                  checked={isRead}
                  onChange={(e) => setIsRead(e.target.checked)}
                />
                <div
                  className={`text-xs ${
                    error ? "!text-red-500" : "text-gray-400"
                  }`}
                >
                  By continuing, I agree to the{" "}
                  <Link
                    to={`${process.env.REACT_APP_API_URL}/terms-and-conditions`}
                    target="_blank"
                    className={`underline  ${
                      error ? "!text-red-500" : "text-black"
                    }`}
                  >
                    Terms And Conditions
                  </Link>{" "}
                  and{" "}
                  <Link
                    to={`${process.env.REACT_APP_API_URL}/privacy-policy`}
                    target="_blank"
                    className={`underline  ${
                      error ? "!text-red-500" : "text-black"
                    }`}
                  >
                    Privacy Policy
                  </Link>
                </div>
              </Fragment>
            ) : (
              <Fragment>
                <input
                  type="checkbox"
                  className="rounded-full"
                  checked={acceptedTermsDel}
                  onChange={(e) => setAcceptedTermsDel(e.target.checked)}
                />
                <div className={cn(["text-xs", error ? "text-red-500" : ""])}>
                  I have read and agree to above terms.
                </div>
              </Fragment>
            )}
          </label>
          {user.token !== "" && (
            <div className="grid grid-cols-2 gap-6 sm:pt-[44px] pt-4">
              <ButtonLogin
                label={"Delete"}
                className={
                  "text-red-500 bg-[#FFEECD] h-[44px] font-bold rounded-lg shadow-lg shadow-[#FFEECD] hover:shadow-inner"
                }
                action={onDeleteAccount}
              />
              <ButtonLogin
                label={"Cancel"}
                className={
                  "text-[#FFEECD] bg-red-500 h-[44px] font-bold rounded-lg shadow-lg shadow-red-500 hover:shadow-inner"
                }
                action={onCancelDelAccount}
              />
            </div>
          )}
        </div>
      </div>
      {loading && <LoadingScreen/>}
    </Fragment>
  );
}

export default App;
